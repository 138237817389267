/**
 * additional breakpoint names taked from
 * https://en.wikipedia.org/wiki/Display_resolution#Common_display_resolutions
 */
export const BREAKPOINTS = {
    values: {
      xs: 576,
      sm: 768,
      md: 1440,
      lg: 1920,
      xl:2560,
      xxl: 3840,
    },
  };

  